<template>
  <div
    v-if="!disabled"
    ref="chatMessageWrapper"
    class="d-flex flex-column"
  >
    <chat-upload-media
      ref="chat_upload"
      :talk-id="talkId"
      @close="onCancelUpload"
      @ready="attachMedia"
    />
    <div
      v-show="emojiPickerState"
      class="e-picker"
      :style="!emojiPickerState ? 'height: 0;' : ''"
    >
      <e-picker
        :dark="false"
        :emoji-with-border="false"
        :emojis-by-row="6"
        class="mx-1 mb-2"
        :style="ePickerStyle"
        @select="onSelectEmoji"
      />
    </div>
    <div class="talk-input">
      <div class="chat-input__container">
        <textarea
          ref="chat_input"
          v-model="newMsg"
          rows="1"
          class="chat-input__input"
          :placeholder="$t('eChat_typeHere')"
          :disabled="disabled"
          @click="manageCaps"
          @keyup.stop.prevent="(e) => { resizeTextArea(); manageCaps(e); }"
        />
        <button
          type="submit"
          class="chat-input__emoji-button"
          :disabled="disabled"
          @click="onToggleEmoji"
        >
          <font-awesome-icon :icon="['fas', 'face-smile']" size="1x" />
        </button>
        <button
          type="submit"
          class="chat-input__upload-button"
          :disabled="disabled"
          @click="_onFileExplorerOpen"
        >
          <font-awesome-icon :icon="['fas', 'paperclip']" size="1x" />
        </button>
      </div>
      <div class="chat-btns__container">
        <button
          type="submit"
          class="chat-input__submit-button"
          :disabled="(!media && newMsg==='') || disabledSendButton || disabled"
          @click="send()"
        >
          <font-awesome-icon :icon="['fas', 'paper-plane']" size="2x" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { VEmojiPicker as EPicker } from 'v-emoji-picker'
import { mapActions } from 'vuex'
import ChatUploadMedia from './ChatUploadMedia'

export default {
  name: 'ChatMessageForm',
  components: {
    EPicker,
    ChatUploadMedia
  },
  props: {
    talkId: {
      type: [String, Number],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      newMsg: '',
      media: undefined,
      t_media: undefined,
      emojiPickerState: false,
      disabledSendButton: false
    }
  },
  computed: {
    ePickerStyle () {
      let height = 440
      // detect top
      if (window) {
        height = window.innerHeight > 605 ? 440 : window.innerHeight - 180
      }
      return `height: 100%; max-height: ${height}px;`
    }
  },
  methods: {
    ...mapActions({
      onFileExplorerClosed: 'chat/onFileExplorerClosed',
      onFileExplorerOpen: 'chat/onFileExplorerOpen',
      setFileExplorerObjectUrl: 'chat/setFileExplorerObjectUrl'
    }),
    resizeTextArea () {
      const maxHeight = 160
      const textArea = this.$refs.chat_input
      if (typeof textArea !== 'undefined') {
        // const uploadContainer = this.$refs.chat_upload
        const style = (window.getComputedStyle) ? window.getComputedStyle(textArea) : textArea.currentStyle
        // This will get the line-height only if it is set in the css,
        // otherwise it's "normal"
        const textAreaLineHeight = parseInt(style.lineHeight, 10)
        // Get the scroll height of the textarea
        const contentHeight = this.calculateContentHeight(textAreaLineHeight)

        if (textArea.clientHeight < contentHeight && textArea.clientHeight < 70) {
          if (contentHeight >= maxHeight) {
            textArea.style.height = maxHeight + 'px'
          } else {
            textArea.style.height = contentHeight + 'px'
          }
        }

        if (textArea.clientHeight > 44 && textArea.clientHeight > contentHeight) {
          textArea.style.height = contentHeight + 'px'
        }

        if (contentHeight > 150) {
          textArea.style.overflowY = 'scroll'
        } else {
          textArea.style.overflow = 'hidden'
        }
      }
    },
    manageCaps (e) {
      const locale = navigator.language
      const textArea = this.$refs.chat_input
      const length = this.newMsg.length
      if (e.type === 'keyup') {
        if (e.keyCode === 13 || e.key === 'Enter') {
          this.capsState = true
        } else if (this.capsState || length === 1) {
          this.capsState = false
          this.$nextTick(() => {
            const cursorPos = textArea.selectionStart
            const lastLetter = this.newMsg.substr((cursorPos - 1), 1)
            this.newMsg = this.newMsg.substr(0, (cursorPos - 1)) +
            lastLetter.toLocaleUpperCase(locale) +
            this.newMsg.substr(cursorPos, this.newMsg.length)
            this.$nextTick(() => {
              textArea.selectionStart = cursorPos
              textArea.selectionEnd = cursorPos
            })
          })
        }
      } else if (e.type === 'click') {
        this.$nextTick(() => {
          const cursorPos = textArea.selectionStart
          const lastLetter = this.newMsg.substr((cursorPos - 1), 1)
          this.capsState = !!lastLetter.match(/[\n\r]/)
        })
      }
    },
    calculateContentHeight (lineHeight) {
      const textArea = this.$refs.chat_input
      const origHeight = textArea.style.height
      const scrollHeight = textArea.scrollHeight
      const overflow = textArea.style.overflow
      let height = textArea.offsetHeight
      // only bother if the textArea is bigger than content
      if (height >= scrollHeight) {
        textArea.style.height = (height + lineHeight) + 'px'
        // because the scrollbar can cause calculation problems
        textArea.style.overflow = 'hidden'
        // by checking that scrollHeight has updated
        if (scrollHeight < textArea.scrollHeight) {
          // now try and scan the textArea's height downwards
          // until scrollHeight becomes larger than height
          while (textArea.offsetHeight >= textArea.scrollHeight) {
            textArea.style.height = (height -= lineHeight) + 'px'
          }
          // be more specific to get the exact height
          while (textArea.offsetHeight < textArea.scrollHeight) {
            textArea.style.height = (height++) + 'px'
          }
          /// reset the textArea back to its original height
          textArea.style.height = origHeight
          // put the overflow back
          textArea.style.overflow = overflow
          return height
        }
      } else {
        return scrollHeight
      }
    },
    onToggleEmoji () {
      if (typeof this.media !== 'undefined') {
        this.t_media = this.media
        this.onFileExplorerClosed(this.talkId)
      }
      this.emojiPickerState = !this.emojiPickerState
    },
    _onFileExplorerOpen () {
      if (this.emojiPickerState) {
        this.emojiPickerState = false
      }
      this.onFileExplorerOpen(this.talkId)
    },
    onSelectEmoji (emoji) {
      this.emojiPickerState = false
      const sStart = this.$refs.chat_input.selectionStart
      const sEnd = this.$refs.chat_input.selectionEnd
      this.newMsg = this.newMsg.substr(0, sStart) +
        emoji.data +
        this.newMsg.substr(sEnd, this.newMsg.length)
      if (typeof this.t_media !== 'undefined' && this.t_media !== null) {
        this.media = this.t_media
        this.setFileExplorerObjectUrl({ talkId: this.talkId, value: URL.createObjectURL(this.t_media[0]) })
        this.t_media = undefined
      }
    },
    async send () {
      const payload = {
        message: this.newMsg,
        media: this.media
      }
      await this.$emit('send', payload)
      this.$nextTick(() => {
        this.newMsg = ''
        this.media = null
        this.setFileExplorerObjectUrl({ talkId: this.talkId, value: undefined })
        this.$nextTick(() => {
          this.resizeTextArea()
        })
      })
    },
    onCancelUpload () {
      this.media = undefined
    },
    attachMedia (files) {
      this.media = files
      this.emojiPickerState = false
    }
  }
}
</script>

<style lang="scss" scoped>
.talk-input {
  position: relative;
  display: flex;
  background-color: transparent;
  justify-content: space-between;
  min-height: 50px;
}
.chat-input__container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.chat-input__container button {
  border: none;
  width: auto;
  height: auto;
  margin: 0;
}
.chat-input__container button svg {
  width: 26px;
  height: 26px;
  color: #636363;
}
.chat-input__container button.chat-input__upload-button {
  background: rgba(0,0,0,0);
  position: absolute;
  bottom: 10px;
  right: 16px;
}
.chat-input__container button.chat-input__emoji-button {
  background: rgba(0,0,0,0);
  position: absolute;
  bottom: 10px;
  right: 50px;
}
textarea.chat-input__input {
  resize: none;
  overflow: hidden;
  width: 100%;
  border: none;
  box-sizing: border-box;
  padding: 10px 92px 10px 10px;
  margin-left: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
  color: #202020;
  background-color: #f0f0f0;
  border-radius: 2rem;
}
textarea.chat-input__input:focus {
  outline: none;
}
.talk-input button.chat-input__submit-button {
  background-color: transparent;
  border: none;
  margin-right: 6px;
  margin-left: 3px;
}
.chat-btns__container {
  display: flex;
  align-items: center;
}
.e-picker {
  height: calc(100% - 50px);
}
</style>
