import { Line, mixins } from 'vue-chartjs'

import { Chart } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
const { reactiveProp } = mixins

Chart.pluginService.register(annotationPlugin)

export default {
  name: 'LineChart',
  extends: Line,
  mixins: [reactiveProp],
  props: ['chartData', 'options'],
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  methods: {
    reRender () {
      this.renderChart(this.chartData, this.options)
    }
  }
}
