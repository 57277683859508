<template>
  <div
    class="chat-message-list w-100 h-100 d-flex flex-column-reverse"
    @scroll="onScroll"
  >
    <div
      v-for="(group, i) in listItems"
      :key="group.date"
      class="chat-message-group"
    >
      <div class="chat-message-group__header text-center my-3">
        {{ group.date }}
      </div>
      <div class="chat-message-group__items d-flex flex-column-reverse">
        <chat-message-item
          v-for="(item, j) in group.messages"
          :key="`${group.date}-${item.id}`"
          :item="item"
          :group-key="i"
          :message-key="j"
          :delete-busy="deleteBusy"
          :disabled="disabled"
          :assigned="assigned"
          @delete="onDelete"
        />
      </div>
    </div>
    <div v-if="busy" class="chat-message-loading">
      <spinner />
    </div>
  </div>
</template>

<script>
import * as moment from 'moment'
import { mapFields } from 'vuex-map-fields'
import ChatMessageItem from './ChatMessageItem'

export default {
  name: 'ChatMessageList',
  components: { ChatMessageItem },
  props: {
    items: {
      type: Array,
      default: () => ([]),
      required: true
    },
    pagination: {
      type: Object,
      default: () => ({}),
      required: true
    },
    busy: {
      type: Boolean,
      default: false
    },
    deleteBusy: {
      type: [String, Number],
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    assigned: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      key: 0,
      listItems: this.groupItems(this.items),
      unreadItems: []
    }
  },
  computed: {
    ...mapFields({
      loadingEnable: 'loadingEnable'
    }),
    loadedMessagesCount () {
      return this.items.length
    }
  },
  watch: {
    items: {
      handler (items) {
        this.listItems = this.groupItems(items)
        this.$nextTick(() => {
          this.key++
          this.$forceUpdate()
        })
      },
      deep: true
    }
  },
  methods: {
    groupItems (items) {
      if (items && items.length) {
        const grouped = []
        for (const message of items) {
          const group = grouped.find(i => i.date === moment(message.sent).format('DD.MM.YYYY'))
          if (group) {
            group.messages.push(message)
          } else {
            grouped.push({
              date: moment(message.sent).format('DD.MM.YYYY'),
              messages: [message]
            })
          }
        }
        return grouped
      }
      return []
    },
    getUnreadItems (items) {
      if (items && items.length) {
        const grouped = []
        for (const message of items) {
          const group = grouped.find(i => i.date === moment(message.sent).format('DD.MM.YYYY'))
          if (group) {
            group.messages.push(message)
          } else {
            grouped.push({
              date: moment(message.sent).format('DD.MM.YYYY'),
              messages: [message]
            })
          }
        }
        return grouped
      }
      return []
    },
    getAvatarPath (item) {
      return item?.sender?.avatar || ''
    },
    onScroll (e) {
      const el = e.target
      const limit = (el.scrollHeight - el.clientHeight)
      if (
        (limit - el.scrollTop * -1) <= el.clientHeight &&
        !this.busy && this.pagination &&
        this.pagination.current_page < this.pagination.total_pages
      ) {
        this.$emit('load', this.pagination.current_page + 1)
      }
    },
    async onDelete (id) {
      await this.$emit('delete', id)
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-message-list {
  overflow: hidden;
  overflow-y: auto;
}
.chat-message-item {
  &:nth-child(odd) {
    background: rgba(225, 225, 225, 0.5);
  }
  &:nth-child(even) {
    background: rgba(166, 166, 166, 0.5);
  }
}
.chat-message-avatar {
  width: 4rem;
}
.chat-message-text {
  width: calc(100% - 4rem)
}
.chat-message-group {
  position: relative;
  &__header {
    background-color: rgba(255, 255, 255, 0.7);
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
.seen {
  margin-left: -10px;
}
</style>
