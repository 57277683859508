<template>
  <div
    :key="`${groupKey}-${messageKey}`"
    class="chat-message-item d-inline-flex px-2 py-1 w-100"
    :class="`${item.deleted_admin ? 'bg-danger' : ''} ${item.deleted_sender || item.deleted_receiver ? 'bg-warning' : ''}`"
  >
    <div
      class="chat-message-avatar text-center"
      :class="itemSenderType === 'staff' ? 'order-2': 'order-1'"
    >
      <b-avatar
        class="rounded-circle mt-2"
        variant="info"
        size="3rem"
        :src="getAvatarPath(item)"
      />
    </div>
    <div
      class="chat-message-text bg-red p-2"
      :class="itemSenderType === 'staff' ? 'order-1 text-right': 'order-2'"
    >
      <div class="d-flex justify-content-between">
        <a
          v-if="!item.deleted_admin && item.id !== deleteBusy && !disabled"
          href="#"
          @click.prevent.stop="onDelete(item.id)"
        >
          <font-awesome-icon
            :icon="['fas', 'trash']"
            class="small"
          />
        </a>
        <span v-else-if="item.deleted_admin && item.id !== deleteBusy" class="small">
          <spinner v-if="item.id === deleteBusy" />
          <span v-else>
            {{ $t('eChat_deletedAdmin') }}
          </span>
        </span>
        <span v-else />
        <span v-if="!item.deleted_admin && (item.deleted_sender || item.deleted_receiver)" class="small">
          {{ $t('eChat_deletedUser') }}
        </span>
        <span>
          <span class="small">{{ $dateFormat(item.sent, 'HH:mm') }}</span>
          <font-awesome-icon
            v-if="disabled || itemSenderType === 'staff'"
            :icon="['fas', 'check']"
            class="text-success"
          />
          <font-awesome-icon
            v-if="disabled || itemSenderType === 'staff'"
            :icon="['fas', 'check']"
            class="seen"
            :class="item.status === 'read' ? 'text-success' : 'text-dark'"
          />
        </span>
      </div>
      <b-link
        v-if="item.type === 'image'"
        target="_blank"
        :href="item.media_url"
      >
        <b-img
          :src="item.media_url"
          width="220"
          height="220"
          rounded
          thumbnail
          fluid
        />
      </b-link>
      <span v-shtml="item.message" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatMessageItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    groupKey: {
      type: [Number, String],
      default: 0,
      required: true
    },
    messageKey: {
      type: [Number, String],
      default: 0,
      required: true
    },
    deleteBusy: {
      type: [String, Number],
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    assigned: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    itemSenderType () {
      return this.item?.sender?.type || ''
    }
  },
  watch: {
  },
  methods: {
    getAvatarPath (item) {
      return item?.sender?.avatar || ''
    },
    async onDelete (id) {
      await this.$emit('delete', id)
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-message-list {
  overflow: hidden;
  overflow-y: auto;
}
.chat-message-item {
  &:nth-child(odd) {
    background: rgba(225, 225, 225, 0.5);
  }
  &:nth-child(even) {
    background: rgba(166, 166, 166, 0.5);
  }
}
.chat-message-avatar {
  width: 4rem;
}
.chat-message-text {
  width: calc(100% - 4rem)
}
.chat-message-group {
  position: relative;
  &__header {
    background-color: rgba(255, 255, 255, 0.7);
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
.seen {
  margin-left: -10px;
}
</style>
