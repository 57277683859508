<template>
  <div v-if="talk" class="d-flex flex-row justify-content-between w-100">
    <user-profile-item
      :user="talk.user"
      :no-links="true"
      :name-variant="'none'"
      class="chat-talk-item"
      @click="onClick(talk.id)"
    />
    <div class="d-block text-right">
      <span v-shtml="$dateFormat(talk.last_message_sent, 'DD/MM/YYYY<br>HH:mm')" class="d-block text-right small" />
      <span
        v-if="talk.messages_unread_count > 0"
        class="badge rounded-pill badge-notification bg-danger support-talk-badge ml-3"
      >
        {{ messagesUnreadDisplay(talk.messages_unread_count) }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ChatTalkItem',
  props: {
    talk: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters({
      isLocked: 'shared/isLocked',
      isLockedBy: 'shared/isLockedBy'
    })
  },
  methods: {
    onClick (id) {
      this.$emit('click', id)
    },
    messagesUnreadDisplay (messagesUnreadCount) {
      return parseInt(messagesUnreadCount) < 100 ? messagesUnreadCount : '99+'
    }
  }
}
</script>

<style scoped>

</style>
